import clientsService from "services/clients";

const updateClubModel = async ({ accessToken, clientId, data }) => {
  const requestData = {
    client: {
      club_id: data.clubId,
    },
  };

  const response = await clientsService.updateClub(accessToken, {
    requestData,
    clientId,
  });

  if (response.errors) return response;

  return { clientId };
};

export default updateClubModel;
