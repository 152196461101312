import { useState, useRef } from "react";
import Link from "next/link";
import classNames from "classnames/bind";
import passwordsService from "services/passwords";
import useErrorNoticeVisbility from "hooks/useErrorNoticeVisibility";
import { Button, ErrorNoticeBanner, FormField, Panel } from "components";
import { linkFor } from "utils";
import styles from "./ForgotPasswordForm.module.scss";
import { EMAILS } from "lib/constants";

const cx = classNames.bind(styles);

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const bannerNotice = useRef();
  const [errors, setErrors] = useErrorNoticeVisbility(bannerNotice);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await passwordsService.requestForgotPasswordEmail({
      email,
    });

    if (response.errors) {
      setEmailSent(false);
      setErrors(response.errors);
      return;
    }

    setEmailSent(true);
  };

  if (emailSent) {
    return (
      <Panel>
        <div className={cx("container")}>
          <h2 className={cx("title")}>Password reset link sent</h2>
          <p>
            If the email address you have provided is registered with Canada
            Life you will receive a password reset email very soon.
          </p>
          <p>
            Please check your email inbox (and spam/junk) for your password
            reset link. If you are having trouble logging in, please contact us
            by emailing{" "}
            <a
              className={cx("textLink")}
              href={`mailto:${EMAILS.adviserSupport}`}
            >
              {EMAILS.adviserSupport}
            </a>{" "}
            or calling{" "}
            <a className={cx("textLink")} href="tel:08000680212">
              0800 068 0212
            </a>
          </p>
        </div>
        <Button fullWidth href={linkFor("login")}>
          Return to login
        </Button>
      </Panel>
    );
  }

  return (
    <Panel>
      <form className={cx("container")} onSubmit={handleSubmit}>
        <ErrorNoticeBanner errors={errors} ref={bannerNotice} />
        <div>
          <h2 className={cx("title")}>Forgot password</h2>
          <p>
            Please enter your email address and we’ll send you a link to reset
            your password.
          </p>
        </div>
        <FormField.Email
          errorText="Please check email address and try again"
          isRequired
          label="Email address"
          onChange={(e) => setEmail(e.target.value)}
          showRequiredText={false}
          value={email}
        />
        <div className={cx("actions")}>
          <Button isDisabled={loading} fullWidth>
            Continue
          </Button>
          <Link href={linkFor("login")} className={cx("textLink")}>
            Return to login
          </Link>
        </div>
      </form>
    </Panel>
  );
};

export default ForgotPasswordForm;
