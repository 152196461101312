import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import useStateOrSessionStorage from "hooks/useStateOrSessionStorage";
import useErrorNoticeVisbility from "hooks/useErrorNoticeVisibility";
import classNames from "classnames/bind";
import {
  ErrorNoticeBanner,
  FormActions,
  FormField,
  NoticeBanner,
  PageHeader,
  Panel,
} from "components";
import {
  linkFor,
  clearSessionStorageItemsWithString,
  isValidPostcode,
} from "utils";
import styles from "./PropertyDetailsForm.module.scss";
import {
  REMAINING_LEASE_BASE,
  REMAINING_LEASE_MAX,
  CLIENT_DETAILS_TYPE_NEW,
  FALSY_STRING,
  TRUTHY_STRING,
  POSTCODE_NOT_FOUND,
  POSTCODE_OUTSIDE_OF_LENDING_CRITERIA,
  PROPERTY_DETAILS_TYPE_NEW,
  PROPERTY_DETAILS_TYPE_UPDATE,
  FORM_STORE_STATE,
  FORM_STORE_SESSION,
  PROPERTY_VALUE_TOO_HIGH,
  PROPERTY_VALUE_MAX,
} from "lib/constants";
import { useSession } from "next-auth/react";
import { getAccessToken } from "utils/oauth";
import updatePropertyDetailsModel from "models/client/updatePropertyDetails";
import { postcodeErrorMap } from "forms/LendingCheckForm";
import lendingCriteriaCheckModel from "models/client/lendingCriteriaCheck";

const cx = classNames.bind(styles);

const PropertyDetailsForm = (props) => {
  const { type = PROPERTY_DETAILS_TYPE_NEW } = props;
  const isNewEntry = type === PROPERTY_DETAILS_TYPE_NEW;
  const stateOrSession = isNewEntry ? FORM_STORE_SESSION : FORM_STORE_STATE;

  const { data: session } = useSession();
  const [formSending, setFormSending] = useState();
  const bannerNotice = useRef();

  const [errors, setErrors] = useErrorNoticeVisbility(bannerNotice);

  const [hasLeaseRemainingWarning, setHasLeaseRemainingWarning] =
    useState(false);

  const [postcode, setPostcode] = useStateOrSessionStorage({
    stateOrSession,
    initialValue: props.postcode,
    key: `postcode-${props.clientId}`,
  });
  const [postcodeError, setPostcodeError] = useState();
  const [exLocalAuthority, setExLocalAuthority] = useStateOrSessionStorage({
    stateOrSession,
    key: `exLocalAuthority-${props.clientId}`,
    initialValue: props.exLocalAuthority,
  });
  const [exLocalAuthorityError, setExLocalAuthorityError] = useState();
  const [propertyType, setPropertyType] = useStateOrSessionStorage({
    stateOrSession,
    key: `propertyType-${props.clientId}`,
    initialValue: props.propertyType,
  });
  const [propertyValue, setPropertyValue] = useStateOrSessionStorage({
    stateOrSession,
    key: `propertyValue-${props.clientId}`,
    initialValue: props.propertyValue,
  });
  const [propertyValueError, setPropertyValueError] = useState();
  const [tenure, setTenure] = useStateOrSessionStorage({
    stateOrSession,
    key: `tenure-${props.clientId}`,
    initialValue: props.tenure,
  });
  const [shelteredAccommodation, setShelteredAccommodation] =
    useStateOrSessionStorage({
      stateOrSession,
      key: `shelteredAccommodation-${props.clientId}`,
      initialValue: props.shelteredAccommodation,
    });
  const [independentValuation, setIndependentValuation] =
    useStateOrSessionStorage({
      stateOrSession,
      key: `independentValuation-${props.clientId}`,
      initialValue: props.independentValuation,
    });
  const [remainingLease, setRemainingLease] = useStateOrSessionStorage({
    stateOrSession,
    key: `remainingLease-${props.clientId}`,
    initialValue: props.remainingLease,
  });

  useEffect(() => {
    if (type !== PROPERTY_DETAILS_TYPE_NEW) {
      setPostcode(props.postcode);
      setExLocalAuthority(props.exLocalAuthority);
      setPropertyType(props.propertyType);
      setPropertyValue(props.propertyValue);
      setTenure(props.tenure);
      setShelteredAccommodation(props.shelteredAccommodation);
      setIndependentValuation(props.independentValuation);
      setRemainingLease(props.remainingLease);
    }
  }, [props]);

  useEffect(() => {
    if (tenure === "leasehold") {
      validateRemainingLeaseField(remainingLease, remainingLeaseThreshold);
    }
  });

  const formRef = useRef();
  const postcodeRef = useRef();
  const tenureRef = useRef();
  const propertyValueRef = useRef();
  const router = useRouter();
  const { clientId } = router.query;

  let cancelLink;
  let backLink;
  let continueLabel;
  let destinationLink;

  switch (type) {
    case PROPERTY_DETAILS_TYPE_NEW:
      continueLabel = "Continue";
      destinationLink = linkFor("loanDetails", { clientId, type });
      backLink = linkFor("clientDetails", {
        clientId,
        type: CLIENT_DETAILS_TYPE_NEW,
      });
      cancelLink = linkFor("dashboard");
      break;

    case PROPERTY_DETAILS_TYPE_UPDATE:
      continueLabel = "Save & Exit";
      destinationLink = linkFor("client", { clientId });
      backLink = destinationLink;
      cancelLink = destinationLink;
      break;
  }

  const remainingLeaseThreshold = Math.max(
    REMAINING_LEASE_BASE - props.borrowerAge,
    REMAINING_LEASE_MAX
  );

  const handlePostcodeChange = ({ target: { value } }) => {
    setPostcode(value);
    setPostcodeError();
    postcodeRef.current.setCustomValidity("");
  };

  const handleExLocalAuthorityChange = ({ target: { value } }) => {
    setExLocalAuthority(value);
    setExLocalAuthorityError();
  };
  const setInvalidPostcode = () => {
    setPostcodeError(POSTCODE_NOT_FOUND);
    postcodeRef.current.setCustomValidity(POSTCODE_NOT_FOUND);
  };

  const handlePropertyTypeChange = (e) => {
    setPropertyType(e.target.value);
    if (e.target.value !== "primary_residence") {
      setShelteredAccommodation(FALSY_STRING);
    }
  };

  const handlePropertyValueChange = ({ target: { value } }) => {
    setPropertyValue(value);
    setPropertyValueError();
    propertyValueRef.current.setCustomValidity("");

    const threshold = parseInt(value.replace(/,/g, ""));

    if (threshold > PROPERTY_VALUE_MAX) {
      setPropertyValueError(PROPERTY_VALUE_TOO_HIGH);
      propertyValueRef.current.setCustomValidity(PROPERTY_VALUE_TOO_HIGH);
    }
  };

  const handleTenureChange = (e) => {
    setTenure(e.target.value);

    e.target.value === "leasehold"
      ? validateRemainingLeaseField(remainingLease, remainingLeaseThreshold)
      : hideRemainingLeaseWarning();
  };

  const handleRemainingLeaseChange = (e) => {
    const input = e.target.value;
    setRemainingLease(input);
    validateRemainingLeaseField(input, remainingLeaseThreshold);
  };

  const handleShelteredAccommodationChange = (e) =>
    setShelteredAccommodation(e.target.value);
  const handleIndependentValuationChange = (e) =>
    setIndependentValuation(e.target.value);

  const validateRemainingLeaseField = (input, threshold) => {
    input < threshold
      ? showRemainingLeaseWarning()
      : hideRemainingLeaseWarning();
  };

  const hideRemainingLeaseWarning = () => {
    setHasLeaseRemainingWarning(false);
  };

  const showRemainingLeaseWarning = () => {
    setHasLeaseRemainingWarning(true);
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    const confirm = window.confirm("Are you sure you want to cancel?");

    if (confirm) {
      clearSessionStorageItemsWithString(clientId);

      router.push({ pathname: cancelLink });
    }
  };

  const validatePostcode = async () => {
    // Validate postcode client-side before making API call
    if (!isValidPostcode(postcode)) {
      setInvalidPostcode();
      return;
    }

    setFormSending(true);

    const { isInvalidPostcode, outsideOfLendingCriteria, isValid } =
      await lendingCriteriaCheckModel(await getAccessToken(session), {
        exLocalAuthority,
        postcode,
      });

    if (isValid) return true;

    setFormSending(false);

    // Checked client-side already, but just to be sure - in case the API says the postcode is invalid
    if (isInvalidPostcode) {
      setInvalidPostcode();
    }

    if (outsideOfLendingCriteria) {
      setPostcodeError(POSTCODE_OUTSIDE_OF_LENDING_CRITERIA);
      postcodeRef.current.setCustomValidity(
        POSTCODE_OUTSIDE_OF_LENDING_CRITERIA
      );
    }

    postcodeRef.current.scrollIntoView({ behavior: "smooth" });

    if (!isValid) return false;
  };

  const validateAndSaveIfValid = async (callbackUrl) => {
    const formIsValid = formRef.current.checkValidity();

    if (!formIsValid) {
      formRef.current.reportValidity();
      return;
    }

    const hasValidPostcode = await validatePostcode();
    if (!hasValidPostcode) return;

    const input = {
      exLocalAuthority,
      independentValuation,
      propertyType,
      propertyValue,
      postcode,
      remainingLease,
      shelteredAccommodation,
      tenure,
    };

    const accessToken = await getAccessToken(session);
    const response = await updatePropertyDetailsModel.patch({
      accessToken,
      clientId,
      input,
    });

    if (response.errors) {
      setFormSending(false);
      setErrors(response.errors);
      return;
    }

    router.push({ pathname: callbackUrl });
  };

  const handleSaveAndExitClick = async (e) => {
    e.preventDefault();
    await validateAndSaveIfValid(linkFor("clients"));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateAndSaveIfValid(destinationLink);
  };

  return (
    <form name="property-details-form" onSubmit={handleSubmit} ref={formRef}>
      <ErrorNoticeBanner errors={errors} ref={bannerNotice} />
      <PageHeader backLink={{ href: backLink, label: "Back" }} />
      <Panel>
        <section className={cx("container")}>
          <header className={cx("header")}>
            <h1 className={cx("title")}>Property details</h1>
            <p className={cx("description")}>
              Please provide the below property information. Some of the
              questions are linked to our lending criteria, which can be found{" "}
              <a
                href={linkFor("lendingCriteria")}
                target="_blank"
                className={cx("link")}
                rel="noreferrer"
              >
                here
              </a>
              . If there are any doubts about whether your customer or their
              property is suitable, please{" "}
              <a
                href={linkFor("email", {
                  email: "hf-underwriting@canadalife.co.uk",
                  subject: "KFI generator - underwriting query",
                })}
                className={cx("link")}
              >
                contact
              </a>{" "}
              our underwriting team.
            </p>
          </header>
          <hr />
          <div className={cx("formFields")}>
            <div className={cx("formField")}>
              <FormField.Text
                errorText={postcodeError}
                hasError={!!postcodeError}
                isRequired
                label="Postcode of the property to be mortgaged"
                onChange={handlePostcodeChange}
                ref={postcodeRef}
                tooltip={{
                  body: "This should be the postcode of the property which the mortgage will be secured on. If your customer is purchasing a new property, please use the new property's postcode here.",
                  title: "Postcode of property",
                }}
                value={postcode}
                controlledValue={postcode}
              />
            </div>
            {postcodeError && (
              <div className={cx("textContainer")} data-testid="postcode-error">
                {postcodeErrorMap[postcodeError]}
              </div>
            )}
            <div className={cx("formField")}>
              <FormField.RadioGroup
                group="ex-local-authority"
                hasError={exLocalAuthorityError}
                initialValue={exLocalAuthority}
                controlledValue={exLocalAuthority}
                isRequired
                label="Is the property ex-local authority or housing association?"
                onChange={handleExLocalAuthorityChange}
                radios={[
                  { label: TRUTHY_STRING, value: TRUTHY_STRING },
                  { label: FALSY_STRING, value: FALSY_STRING },
                ]}
                tooltip={{
                  body: "We do accept some ex-local authority and ex-council houses, but do not accept any ex-local authority or ex-council flats or maisonettes. If this applies, please tick Yes to confirm it complies with our lending criteria.",
                  title: "Ex-local authority properties",
                }}
              />
            </div>
            <div className={cx("formField")}>
              <FormField.Dropdown
                isRequired
                label="Property type"
                onChange={handlePropertyTypeChange}
                options={[
                  { label: "Main residence", value: "primary_residence" },
                  { label: "Second home", value: "second_home" },
                  { label: "Buy-to-let", value: "buy_to_let" },
                ]}
                value={propertyType}
                controlledValue={propertyType}
                tooltip={{
                  title: "Types of property",
                  body: (
                    <>
                      <span className={cx("tooltipSubheader")}>
                        Main residence:
                      </span>
                      <p>
                        A main residence is somebody&apos;s primary residence.
                      </p>
                      <span className={cx("tooltipSubheader")}>
                        Second home:
                      </span>
                      <p>
                        A second home is a property which is for sole occupancy,
                        will be used by the applicant(s) for a minimum of four
                        weeks each year, and will be let out for a maximum of
                        four weeks at a time each year.
                      </p>
                      <span className={cx("tooltipSubheader")}>
                        Buy-to-let:
                      </span>
                      <p>
                        A Buy-to-Let is a property which is let out on an
                        Assured Shorthold Tenancy (AST) of not more than twelve
                        months duration.
                      </p>
                    </>
                  ),
                }}
              />
            </div>
            <div className={cx("formField")}>
              <FormField.RadioGroup
                customInputContainerClass={cx("radioGroup")}
                ref={tenureRef}
                isRequired={false}
                helperText="Optional"
                label="Is the property classed as freehold or leasehold?"
                group="freehold_leasehold"
                onChange={handleTenureChange}
                radios={[
                  { label: "Freehold", value: "freehold" },
                  { label: "Leasehold", value: "leasehold" },
                ]}
                initialValue={tenure}
                controlledValue={tenure}
                tooltip={{
                  body: "We accept freehold houses, bungalows and some flats (Scotland only). Freehold flats, where the freehold is in respect of the whole building subject to leases/tenancies of the remainder of the block, will be considered on an individual basis. We also accept houses, bungalows and flats with a leasehold tenure.",
                  title: "Freehold and leasehold properties",
                }}
              />
            </div>
            {tenure === "leasehold" && (
              <>
                <div className={cx("formField")}>
                  <FormField.Text
                    isRequired={false}
                    helperText="Optional"
                    warningText="Leasehold term too short"
                    hasWarning={hasLeaseRemainingWarning}
                    label="Lease remaining (years)"
                    onChange={handleRemainingLeaseChange}
                    value={remainingLease}
                    controlledValue={remainingLease}
                    tooltip={{
                      body: "We accept leasehold houses, bungalows and flats, with a leasehold tenure with an unexpired term of at least 155 years minus the age of the youngest borrower, or 75 years whichever is the greater, plus confirmation at legal stage that lease is marketable with noonerous clauses.",
                      title: "Remaining leasehold term",
                    }}
                  />
                </div>
                {hasLeaseRemainingWarning && (
                  <div className={cx("noticeBannerContainer")}>
                    <NoticeBanner
                      id="lease_remaining_years"
                      title="Remaining leasehold term too short"
                      type="warning"
                    >
                      We require a leasehold with an unexpired term of at least
                      155 years minus the age of the youngest borrower, or 75
                      years, whichever is greater. You can still generate an
                      illustration, but please confirm in the application that
                      you will be extending the lease or converting to freehold.
                    </NoticeBanner>
                  </div>
                )}
              </>
            )}
            <div className={cx("formField")}>
              <FormField.RadioGroup
                customInputContainerClass={cx("radioGroup")}
                isFrozen={propertyType !== "primary_residence"}
                isRequired
                label="Is the property classed as sheltered accommodation?"
                group="sheltered_accommodation"
                onChange={handleShelteredAccommodationChange}
                radios={[
                  { label: TRUTHY_STRING, value: TRUTHY_STRING },
                  { label: FALSY_STRING, value: FALSY_STRING },
                ]}
                initialValue={shelteredAccommodation}
                controlledValue={shelteredAccommodation}
                tooltip={{
                  body: "If there is a warden facility the property is classed as sheltered accommodation. This must be marked on the Illustration and the LTV will be reduced by 5%. Resale clauses contained in the lease must be referred to our Underwriters.",
                  title: "Sheltered accommodation",
                }}
              />
            </div>
            <div className={cx("formField")}>
              <FormField.Currency
                isRequired
                ref={propertyValueRef}
                errorText={propertyValueError}
                hasError={!!propertyValueError}
                label="Estimated property value"
                onAfterChange={handlePropertyValueChange}
                withDecimal={false}
                value={propertyValue}
                controlledValue={propertyValue}
                tooltip={{
                  title: "Estimated property value",
                  body: (
                    <p>
                      This should be a value which is comparable to other
                      properties which have recently sold in the area.
                      &apos;Sold for&apos; values can be found on websites such
                      as{" "}
                      <a
                        className={cx("tooltipLink")}
                        href="https://www.rightmove.co.uk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.rightmove.co.uk
                      </a>{" "}
                      and{" "}
                      <a
                        className={cx("tooltipLink")}
                        href="https://www.zoopla.co.uk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        www.zoopla.co.uk
                      </a>
                      .
                    </p>
                  ),
                }}
              />
            </div>
            {propertyValueError && (
              <div className={cx("noticeBannerContainer")}>
                <NoticeBanner
                  id="property_value"
                  title="Property value too high"
                  type="alert"
                >
                  The property value must be no greater than £12,000,000.
                </NoticeBanner>
              </div>
            )}
            <div>
              <h2 className={cx("formFieldsTitle")} id="independent_valuation">
                Independent valuation
              </h2>
              <p className={cx("formFieldsHelpText")}>
                If the property valuation comes in higher than the estimate,
                your client may be able to borrow more. We can email you to
                request another KFI based on the higher valuation. If you would
                like to take advantage of this, please select Yes. Please be
                aware that there will be a pause in the application process
                until we receive the new KFI.
              </p>
              <div className={cx("formField")}>
                <FormField.RadioGroup
                  customInputContainerClass={cx("radioGroup")}
                  isRequired
                  labelledby="independent_valuation"
                  group="independent_valuation"
                  onChange={handleIndependentValuationChange}
                  radios={[
                    { label: TRUTHY_STRING, value: TRUTHY_STRING },
                    { label: FALSY_STRING, value: FALSY_STRING },
                  ]}
                  initialValue={independentValuation}
                  controlledValue={independentValuation}
                  tooltip={{
                    title: "Independent valuation",
                    body: (
                      <>
                        <p>
                          If you tick yes, we will suspend processing in order
                          to allow you to discuss requirements with your
                          customer and, if a larger loan amount is chosen,
                          complete an amended KFI.
                        </p>
                        <p>
                          If you tick no, we will continue processing using the
                          loan amount specified in the original KFI.
                        </p>
                      </>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </Panel>
      <FormActions
        continueLabel={continueLabel}
        isDisabled={formSending}
        onCancelClick={handleCancelClick}
        onSaveAndExitClick={handleSaveAndExitClick}
        saveAndExit={isNewEntry}
      />
    </form>
  );
};

PropertyDetailsForm.propTypes = {
  borrowerAge: PropTypes.number.isRequired,
  clientId: PropTypes.string.isRequired,
  exLocalAuthority: PropTypes.oneOf([TRUTHY_STRING, FALSY_STRING]),
  independentValuation: PropTypes.oneOf([TRUTHY_STRING, FALSY_STRING]),
  postcode: PropTypes.string,
  propertyType: PropTypes.oneOf([
    "primary_residence",
    "second_home",
    "buy_to_let",
  ]),
  propertyValue: PropTypes.string,
  remainingLease: PropTypes.string,
  shelteredAccommodation: PropTypes.oneOf([TRUTHY_STRING, FALSY_STRING]),
  tenure: PropTypes.oneOf(["freehold", "leasehold"]),
  type: PropTypes.oneOf([
    PROPERTY_DETAILS_TYPE_NEW,
    PROPERTY_DETAILS_TYPE_UPDATE,
  ]),
};

export default PropertyDetailsForm;
