import { useEffect, useRef } from "react";
import { FormActions, FormField, PageHeader, Panel } from "components";
import {
  clearSessionStorageItemsWithString,
  formatCurrency,
  linkFor,
  parseAmountToInteger,
} from "utils";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./EditIllustrationForm.module.scss";
import {
  EXTERNAL_LINKS,
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
} from "lib/constants";
import { SimpleTable } from "tables";
import { useRouter } from "next/router";
import useSessionStorage from "hooks/useSessionStorage";

const cx = classNames.bind(styles);

const EditIllustrationForm = ({
  adviceFee,
  cashReserve,
  clientId,
  initialAdvanceAmount,
  propertyValue,
  recommended,
  tableData,
  type,
}) => {
  const router = useRouter();
  const formRef = useRef();
  const estimatedPropertyValueRef = useRef();
  const { referenceNo } = router.query;

  useSessionStorage(`clientId-${referenceNo}`, clientId);
  useSessionStorage(`adviceFee-${referenceNo}`, adviceFee);
  if (type) useSessionStorage(`recommended-${referenceNo}`, recommended);
  if (cashReserve) useSessionStorage(`cashReserve-${referenceNo}`, cashReserve);
  if (initialAdvanceAmount) {
    useSessionStorage(
      `initialAdvanceAmount-${referenceNo}`,
      initialAdvanceAmount
    );
  }

  const [estimatedPropertyValue, setEstimatedPropertyValue] = useSessionStorage(
    `estimatedPropertyValue-${referenceNo}`,
    parseAmountToInteger(propertyValue)
  );

  const handleEstimatedPropertyValueChange = (e) => {
    setEstimatedPropertyValue(parseAmountToInteger(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formIsValid = formRef.current.checkValidity();

    if (!formIsValid) {
      formRef.current.reportValidity();
      return;
    }

    const illustrationDetailsLinkType =
      type === ILLUSTRATION_DETAILS_TYPE_REPLACEMENT
        ? "replacementLoanDetails"
        : "amendmentLoanDetails";

    router.push({
      pathname: linkFor(illustrationDetailsLinkType, { referenceNo }),
    });
  };

  const handleCancelClick = (e) => {
    e.preventDefault();
    const confirm = window.confirm("Are you sure you want to cancel?");

    if (confirm) {
      clearSessionStorageItemsWithString(referenceNo);
      router.push({ pathname: linkFor("dashboard") });
    }
  };

  const estimatedPropertyValueSection = () => {
    if (type === ILLUSTRATION_DETAILS_TYPE_REPLACEMENT) {
      return (
        <>
          <hr />
          <header className={cx("header")}>
            <h1 className={cx("title")}>Estimated property value</h1>
          </header>
          <div className={cx("formField")}>
            <FormField.Currency
              label="Estimated property value"
              onAfterChange={handleEstimatedPropertyValueChange}
              isRequired
              ref={estimatedPropertyValueRef}
              withDecimal={true}
              value={formatCurrency(estimatedPropertyValue)}
              controlledValue={formatCurrency(estimatedPropertyValue)}
              tooltip={{
                displayOutsideContainer: true,
                body: (
                  <>
                    This should be a value which is comparable to other
                    properties which have recently sold in the area. &apos;Sold
                    for&apos; values can be found on websites such as{" "}
                    <a
                      href="https://www.rightmove.co.uk"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      www.rightmove.co.uk
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.zoopla.co.uk"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      www.zoopla.co.uk
                    </a>
                    .
                  </>
                ),
                title: "Estimated property value",
              }}
            />
          </div>
        </>
      );
    }
  };

  const descriptionText = () => {
    switch (type) {
      case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
        return (
          <>
            The replacement illustration will be created using the following
            property details. If any of these are incorrect, please{" "}
            <a href={EXTERNAL_LINKS.contact} className={cx("textLink")}>
              contact us
            </a>
            .
          </>
        );
      case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
        return (
          <>
            The value of the property has been adjusted following the recent
            property valuation. If you would like us to review this valuation
            figure before proceeding, please complete and return a{" "}
            <a
              href={EXTERNAL_LINKS.valuationReviewRequest}
              className={cx("textLink")}
            >
              Valuation Review Request
            </a>
            .
          </>
        );
    }
  };

  useEffect(() => {
    setEstimatedPropertyValue(parseAmountToInteger(propertyValue));
  }, [propertyValue]);

  return (
    <form name={`kfi-${type}-form`} onSubmit={handleSubmit} ref={formRef}>
      <PageHeader
        backLink={{ href: linkFor("dashboard"), label: "Back to dashboard" }}
      />
      <Panel>
        <section className={cx("container")}>
          <header className={cx("header")}>
            <h1 className={cx("title")}>Property details</h1>
            <p className={cx("description")}>{descriptionText()}</p>
          </header>
          <SimpleTable
            configuration="EditIllustrationPropertyDetails"
            tableData={tableData}
          />
          {estimatedPropertyValueSection()}
        </section>
      </Panel>
      <FormActions onCancelClick={handleCancelClick} />
    </form>
  );
};

EditIllustrationForm.propTypes = {
  adviceFee: PropTypes.string.isRequired,
  cashReserve: PropTypes.string,
  clientId: PropTypes.number.isRequired,
  initialAdvanceAmount: PropTypes.string,
  propertyValue: PropTypes.string.isRequired,
  recommended: PropTypes.bool.isRequired,
  tableData: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};
export default EditIllustrationForm;
