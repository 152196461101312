import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { getAccessToken } from "utils/oauth";
import PropTypes from "prop-types";
import qs from "qs";
import classNames from "classnames/bind";
import useErrorNoticeVisbility from "hooks/useErrorNoticeVisibility";
import useProductListState from "hooks/useProductListState";
import {
  Button,
  BracketInfo,
  ErrorNoticeBanner,
  FormActions,
  FormField,
  PageHeader,
  Panel,
} from "components";
import {
  CASH_RESERVE_CANNOT_BE_MAX,
  FALSY_STRING,
  ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
  ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
  LOAN_DETAILS_TYPE_NEW,
  LOAN_DETAILS_TYPE_UPDATE,
  MAXIMUM_AMOUNT_AVAILABLE,
  PROPERTY_DETAILS_TYPE_NEW,
  SPECIFIC_AMOUNT,
  TRUTHY_STRING,
} from "lib/constants";
import useSessionStorage from "hooks/useSessionStorage";
import {
  linkFor,
  displayAmountWithCurrency,
  parseAmountToInteger,
  parseCurrency,
  clearSessionStorageItemsWithString,
} from "utils";
import updateLoanInformationModel from "models/client/updateLoanInformation";
import styles from "./LoanDetailsForm.module.scss";

const cx = classNames.bind(styles);

const LoanDetailsForm = (props) => {
  const { type = LOAN_DETAILS_TYPE_NEW } = props;
  const router = useRouter();
  const { referenceNo } = router.query;
  const clientId =
    router.query.clientId || sessionStorage.getItem(`clientId-${referenceNo}`);
  const isNewEntry = type === LOAN_DETAILS_TYPE_NEW;
  const isEditOrUpdate = [
    LOAN_DETAILS_TYPE_NEW,
    LOAN_DETAILS_TYPE_UPDATE,
  ].includes(type);

  const useStateOrSessionStorage = (key, initialValue) => {
    switch (type) {
      case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
      case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
        return useSessionStorage(`${key}-${referenceNo}`, initialValue);

      case LOAN_DETAILS_TYPE_NEW:
      case LOAN_DETAILS_TYPE_UPDATE:
        return useSessionStorage(`${key}-${clientId}`, initialValue);
    }
  };

  const { data: session } = useSession();
  const [formSending, setFormSending] = useState();
  const [estimatedPropertyValue, setEstimatedPropertyValue] =
    useStateOrSessionStorage(
      "estimatedPropertyValue",
      props.estimatedPropertyValue
    );
  const [initialAdvanceAmount, setInitialAdvanceAmount] =
    useStateOrSessionStorage(
      "initialAdvanceAmount",
      props.initialAdvanceAmount
    );
  const [cashReserve, setCashReserve] = useStateOrSessionStorage(
    "cashReserve",
    props.cashReserve
  );
  const [cashReserveError, setCashReserveError] = useState(false);
  const [editEstimatedPropertyValue, setEditEstimatedPropertyValue] =
    useState();
  const bannerNotice = useRef();
  const [errors, setErrors] = useErrorNoticeVisbility(bannerNotice);
  const formRef = useRef();
  const estimatedPropertyValueRef = useRef();
  const initialAdvanceAmountRef = useRef();
  const showSpecificInitialAdvanceAmountRef = useRef();
  const cashReserveRef = useRef();
  const reserveAmountNeededRef = useRef();
  const showSpecificCashReserveAmountRef = useRef();

  const [requestData, updateRequestData] = useProductListState({});

  let cancelLink;
  let backLink;
  let continueLabel;
  let destinationLink;

  switch (type) {
    case ILLUSTRATION_DETAILS_TYPE_REPLACEMENT:
      continueLabel = "Continue";
      destinationLink = linkFor("replacementProducts", { referenceNo });
      backLink = {
        href: linkFor("application", {
          referenceNo,
          status: ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
        }),
        label: "Back to illustration request",
      };
      cancelLink = linkFor("dashboard");
      break;

    case ILLUSTRATION_DETAILS_TYPE_AMENDMENT:
      continueLabel = "Continue";
      destinationLink = linkFor("amendmentProducts", { referenceNo });
      backLink = {
        href: linkFor("application", {
          referenceNo,
          status: ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
        }),
        label: "Back to illustration request",
      };
      cancelLink = linkFor("dashboard");
      break;

    case LOAN_DETAILS_TYPE_NEW:
      continueLabel = "Continue";
      destinationLink = linkFor("products", { clientId });
      backLink = {
        href: linkFor("propertyDetails", {
          clientId,
          type: PROPERTY_DETAILS_TYPE_NEW,
        }),
        label: "Back",
      };
      cancelLink = linkFor("dashboard");
      break;

    case LOAN_DETAILS_TYPE_UPDATE:
      continueLabel = "Save & Exit";
      destinationLink = linkFor("client", { clientId });
      backLink = { href: destinationLink, label: "Back" };
      cancelLink = destinationLink;
      break;
  }

  const handleEstimatedPropertyValueChange = (e) => {
    setEstimatedPropertyValue(`${parseAmountToInteger(e.target.value)}`);
  };

  const handleInitialAdvanceChange = (e) => {
    const { value } = e.target;
    setCashReserveError(false);
    cashReserveRef.current.setCustomValidity("");

    setInitialAdvanceAmount(value === "" ? SPECIFIC_AMOUNT : value);
    updateRequestData(initialAdvanceData(value));
  };

  const initialAdvanceData = (value) => {
    if (value !== MAXIMUM_AMOUNT_AVAILABLE) {
      return {
        type: "initial_advance",
        facet: SPECIFIC_AMOUNT,
        value: parseAmountToInteger(value),
      };
    }
    return { type: "initial_advance", facet: MAXIMUM_AMOUNT_AVAILABLE };
  };

  const handleCashReserveChange = (e) => {
    const { value } = e.target;
    setCashReserve(value === "" ? SPECIFIC_AMOUNT : value);
    setCashReserveError(false);
    cashReserveRef.current.setCustomValidity("");

    const unusedValues = [TRUTHY_STRING, "", SPECIFIC_AMOUNT];

    if (unusedValues.includes(value)) return;

    updateRequestData(cashReserveData(value));
  };

  const cashReserveData = (value) => {
    switch (value) {
      case MAXIMUM_AMOUNT_AVAILABLE:
        return { type: "cash_reserve", facet: MAXIMUM_AMOUNT_AVAILABLE };

      case FALSY_STRING:
        return { type: "cash_reserve", facet: "none" };

      default:
        return {
          type: "cash_reserve",
          facet: SPECIFIC_AMOUNT,
          value: parseAmountToInteger(value),
        };
    }
  };

  useEffect(() => {
    estimatedPropertyValueRef.current?.focus();
  }, [editEstimatedPropertyValue]);

  useEffect(() => {
    if (initialAdvanceAmount)
      updateRequestData(initialAdvanceData(initialAdvanceAmount));
  }, [initialAdvanceAmount]);

  useEffect(() => {
    if (cashReserve) updateRequestData(cashReserveData(cashReserve));
  }, [cashReserve]);

  const handleCancelClick = (e) => {
    e.preventDefault();
    const confirm = window.confirm("Are you sure you want to cancel?");

    isEditOrUpdate
      ? clearSessionStorageItemsWithString(clientId)
      : clearSessionStorageItemsWithString(referenceNo);

    if (confirm) router.push({ pathname: cancelLink });
  };

  const validateAndSaveIfValid = async (callbackUrl) => {
    const formIsValid = formRef.current.checkValidity();

    // initialAdvanceAmount and cashReserve cannot be both maximum
    if (cashReserve === "maximum" && initialAdvanceAmount === "maximum") {
      setCashReserveError(true);
      return;
    }

    if (!formIsValid) {
      formRef.current.reportValidity();
      return;
    }

    const data = {
      estimatedPropertyValue,
      initialAdvanceAmount,
      cashReserve,
    };

    setFormSending(true);

    if (isEditOrUpdate) {
      const response = await updateLoanInformationModel(
        await getAccessToken(session),
        clientId,
        data
      );

      if (response.errors) {
        setFormSending(false);
        setErrors(response.errors);
        return;
      }
    }

    if (isNewEntry) clearSessionStorageItemsWithString(clientId);

    const nonProductListUrls = [
      linkFor("clients"),
      linkFor("client", { clientId }),
    ];

    const newUrlObject = {
      pathname: callbackUrl,
      query: nonProductListUrls.includes(callbackUrl)
        ? ""
        : qs.stringify(requestData),
    };

    router.push(newUrlObject);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await validateAndSaveIfValid(destinationLink);
  };

  const handleSaveAndExitClick = async (e) => {
    e.preventDefault();

    if (!isNewEntry) return;

    await validateAndSaveIfValid(linkFor("clients"));
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setEditEstimatedPropertyValue(true);
  };

  const showSpecificInitialAdvanceAmount =
    initialAdvanceAmount && initialAdvanceAmount !== MAXIMUM_AMOUNT_AVAILABLE;

  const showCashReserveOptions = cashReserve && cashReserve !== FALSY_STRING;

  const showSpecificCashReserveAmount =
    showCashReserveOptions &&
    cashReserve !== MAXIMUM_AMOUNT_AVAILABLE &&
    cashReserve !== TRUTHY_STRING;

  const getReserveAmountNeededDropdownValue = () => {
    if (!cashReserve || cashReserve === TRUTHY_STRING) return "";
    if (cashReserve === MAXIMUM_AMOUNT_AVAILABLE) return cashReserve;
    if (cashReserve !== FALSY_STRING) return SPECIFIC_AMOUNT;
  };

  const getSpecificCashReserveAmountValue = () => {
    if (cashReserve === SPECIFIC_AMOUNT) return "";
    return cashReserve;
  };

  const getInitialAdvanceAmountDropdownValue = () => {
    if (!initialAdvanceAmount) return "";

    return showSpecificInitialAdvanceAmount
      ? SPECIFIC_AMOUNT
      : MAXIMUM_AMOUNT_AVAILABLE;
  };

  const getSpecificInitialAdvanceAmountValue = () => {
    if (initialAdvanceAmount === SPECIFIC_AMOUNT) return "";
    return initialAdvanceAmount;
  };

  const getMaxReserveAmount = () => {
    const sum =
      parseCurrency(estimatedPropertyValue) -
      parseCurrency(initialAdvanceAmount);

    return Number.isNaN(sum)
      ? Number(parseCurrency(estimatedPropertyValue))
      : sum;
  };

  return (
    <form name="lending-check-form" onSubmit={handleSubmit} ref={formRef}>
      <ErrorNoticeBanner errors={errors} ref={bannerNotice} />
      <PageHeader backLink={backLink} />
      <Panel>
        <section className={cx("container")}>
          <header className={cx("header")}>
            <h1 className={cx("title")}>Loan details</h1>
            <p className={cx("description")}>
              Please provide the below client information. We’ll use these
              details to create an illustration so please make sure the
              information is accurate.
              {isNewEntry &&
                ` If you need to update the details at a
      later stage, please use the Adviser Dashboard and go to Clients
      where you will be able to view your clients details.`}
            </p>
          </header>
          <hr />
          <div className={cx("formFields")}>
            <div className={cx("formField")}>
              {editEstimatedPropertyValue ? (
                <FormField.Currency
                  label="Estimated property value"
                  onAfterChange={handleEstimatedPropertyValueChange}
                  isRequired
                  ref={estimatedPropertyValueRef}
                  withDecimal={false}
                  value={estimatedPropertyValue}
                  tooltip={{
                    displayOutsideContainer: true,
                    body: (
                      <>
                        This should be a value which is comparable to other
                        properties which have recently sold in the area.
                        &apos;Sold for&apos; values can be found on websites
                        such as{" "}
                        <a
                          href="https://www.rightmove.co.uk"
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          www.rightmove.co.uk
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.zoopla.co.uk"
                          target="_blank"
                          rel="nofollow noreferrer"
                        >
                          www.zoopla.co.uk
                        </a>
                        .
                      </>
                    ),
                    title: "Estimated property value",
                  }}
                />
              ) : (
                <div
                  className={cx("estimatedPropertyValueContainer")}
                  data-testid="property-value"
                >
                  <BracketInfo
                    label="Estimated property value"
                    value={displayAmountWithCurrency(estimatedPropertyValue)}
                  />
                  {type !== ILLUSTRATION_DETAILS_TYPE_AMENDMENT && (
                    <Button
                      ariaLabel="Change estimated property value"
                      icon="Edit"
                      onClick={handleEditClick}
                      theme="alternative"
                    >
                      <span className={cx("changePropertyValueText")}>
                        Change
                      </span>
                    </Button>
                  )}
                </div>
              )}
            </div>
            <fieldset className={cx("fieldset")} aria-label="Initial advance">
              <div className={cx("formField")}>
                <FormField.Dropdown
                  onChange={handleInitialAdvanceChange}
                  options={[
                    {
                      label: "Maximum available amount",
                      value: MAXIMUM_AMOUNT_AVAILABLE,
                    },
                    { label: "Specific amount", value: SPECIFIC_AMOUNT },
                  ]}
                  label="Initial advance amount"
                  ref={initialAdvanceAmountRef}
                  tooltip={{
                    displayOutsideContainer: true,
                    title: "Initial advance",
                    body: "An initial advance is a lump sum amount that your customer will receive on the day of completion. If you know your customer wants a precise amount, please choose 'Specific amount'. If your customer needs the full LTV available to them and does not want a cash reserve facility, please choose 'Maximum amount available'.",
                  }}
                  isRequired
                  value={getInitialAdvanceAmountDropdownValue()}
                />
              </div>
              {showSpecificInitialAdvanceAmount && (
                <div className={cx("formField")}>
                  <FormField.Currency
                    containWithinTooltipBounds
                    label="Amount needed"
                    min={0}
                    max={Number(parseCurrency(estimatedPropertyValue))}
                    onAfterChange={handleInitialAdvanceChange}
                    isRequired
                    ref={showSpecificInitialAdvanceAmountRef}
                    withDecimal={false}
                    value={getSpecificInitialAdvanceAmountValue()}
                  />
                </div>
              )}
            </fieldset>
            <fieldset className={cx("fieldset")} aria-label="Cash reserve">
              <div className={cx("formField")}>
                <FormField.RadioGroup
                  onChange={handleCashReserveChange}
                  group="cashReserve"
                  radios={[
                    { label: TRUTHY_STRING, value: TRUTHY_STRING },
                    { label: FALSY_STRING, value: FALSY_STRING },
                  ]}
                  label="Is a cash reserve facility needed?"
                  ref={cashReserveRef}
                  tooltip={{
                    displayOutsideContainer: true,
                    title: "Adding a cash reserve facility",
                    body: "A cash reserve facility is a pre-agreed pot of cash – ready for your customer to access when they need it. They will only pay interest on the cash when they’ve withdrawn it from the facility.",
                  }}
                  isRequired
                  initialValue={
                    cashReserve
                      ? cashReserve === FALSY_STRING
                        ? FALSY_STRING
                        : TRUTHY_STRING
                      : ""
                  }
                />
              </div>
              {showCashReserveOptions && (
                <div className={cx("formField")}>
                  <FormField.Dropdown
                    errorText={CASH_RESERVE_CANNOT_BE_MAX}
                    hasError={cashReserveError}
                    containWithinTooltipBounds
                    onChange={handleCashReserveChange}
                    options={[
                      {
                        label: "Maximum available amount",
                        value: MAXIMUM_AMOUNT_AVAILABLE,
                      },
                      { label: "Specific amount", value: SPECIFIC_AMOUNT },
                    ]}
                    label="Reserve amount needed"
                    ref={reserveAmountNeededRef}
                    isRequired
                    value={getReserveAmountNeededDropdownValue()}
                  />
                </div>
              )}
              {showSpecificCashReserveAmount && (
                <div className={cx("formField")}>
                  <FormField.Currency
                    containWithinTooltipBounds
                    label="Amount needed"
                    min={0}
                    max={getMaxReserveAmount()}
                    onAfterChange={handleCashReserveChange}
                    isRequired
                    ref={showSpecificCashReserveAmountRef}
                    withDecimal={false}
                    value={getSpecificCashReserveAmountValue()}
                  />
                </div>
              )}
            </fieldset>
          </div>
        </section>
      </Panel>
      <FormActions
        continueLabel={continueLabel}
        isDisabled={formSending}
        onCancelClick={handleCancelClick}
        onSaveAndExitClick={handleSaveAndExitClick}
        saveAndExit={isNewEntry}
      />
    </form>
  );
};

LoanDetailsForm.propTypes = {
  estimatedPropertyValue: PropTypes.string,
  type: PropTypes.oneOf([
    ILLUSTRATION_DETAILS_TYPE_REPLACEMENT,
    ILLUSTRATION_DETAILS_TYPE_AMENDMENT,
    LOAN_DETAILS_TYPE_UPDATE,
    LOAN_DETAILS_TYPE_NEW,
  ]),
  initialAdvanceAmount: PropTypes.string,
  cashReserve: PropTypes.string,
};

export default LoanDetailsForm;
