import { lendingCriteriaCheck } from "services/postcodes";
import { postcodesLendingCriteriaCheckResolver } from "resolvers";
import { TRUTHY_STRING } from "lib/constants";

const lendingCriteriaCheckModel = async (
  accessToken,
  { postcode, exLocalAuthority }
) => {
  const req = await lendingCriteriaCheck(accessToken, {
    postcode,
    ex_local_authority: exLocalAuthority === TRUTHY_STRING,
  });

  return postcodesLendingCriteriaCheckResolver(req);
};

export default lendingCriteriaCheckModel;
