import { useState, useEffect } from "react";

const getSessionStorageOrDefault = (key, defaultValue) => {
  const stored = sessionStorage.getItem(key);

  if (!stored) {
    return defaultValue;
  }

  return stored;
};

const useSessionStorage = (key, defaultValue = "") => {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    sessionStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
