import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { getAccessToken } from "utils/oauth";
import classNames from "classnames/bind";
import lendingCriteriaCheckModel from "models/client/lendingCriteriaCheck";
import {
  FormActions,
  FormField,
  NoticeBanner,
  PageHeader,
  Panel,
} from "components";
import useSessionStorage from "hooks/useSessionStorage";
import {
  clearNewClientSessionStorageItems,
  isValidPostcode,
  linkFor,
} from "utils";
import {
  ADVISER_DETAILS_TYPE_NEW,
  EXTERNAL_LINKS,
  FALSY_STRING,
  POSTCODE_NOT_FOUND,
  POSTCODE_OUTSIDE_OF_LENDING_CRITERIA,
  TRUTHY_STRING,
} from "lib/constants";
import styles from "./LendingCheckForm.module.scss";

const cx = classNames.bind(styles);

export const postcodeErrorMap = {
  [POSTCODE_NOT_FOUND]: (
    <NoticeBanner
      id="postcode-not-found"
      title={POSTCODE_NOT_FOUND}
      type="alert"
    >
      Please make sure the postcode is entered in the correct format. If it is
      in the correct format but can’t be found, please{" "}
      <a href={EXTERNAL_LINKS.contact}>contact our underwriters</a> to check the
      property meets our criteria.
    </NoticeBanner>
  ),
  [POSTCODE_OUTSIDE_OF_LENDING_CRITERIA]: (
    <NoticeBanner
      id="postcode-outside-of-lending-criteria"
      title={POSTCODE_OUTSIDE_OF_LENDING_CRITERIA}
      type="alert"
    >
      Please refer to our{" "}
      <a href={EXTERNAL_LINKS.lendingCriteria}>Lending Criteria</a> or{" "}
      <a href={EXTERNAL_LINKS.contact}>contact our underwriters</a> for more
      information.
    </NoticeBanner>
  ),
};

const LendingCheckForm = (props) => {
  const { clientId } = props;

  const useStateOrSessionStorage = (key, initialValue) => {
    const sessionKey = clientId ? `${key}-${clientId}` : key;
    return useSessionStorage(sessionKey, initialValue);
  };

  const { data: session } = useSession();
  const [formSending, setFormSending] = useState();
  const [postcode, setPostcode] = useStateOrSessionStorage(
    "postcode",
    props.postcode
  );
  const [postcodeError, setPostcodeError] = useState();
  const [exLocalAuthority, setExLocalAuthority] = useStateOrSessionStorage(
    "exLocalAuthority",
    props.exLocalAuthority
  );
  const [exLocalAuthorityError, setExLocalAuthorityError] = useState();
  const router = useRouter();
  const formRef = useRef();
  const postcodeRef = useRef();

  const handlePostcodeChange = (e) => {
    setPostcode(e.target.value);
    setPostcodeError();
    postcodeRef.current.setCustomValidity("");
  };

  const handleExLocalAuthorityChange = (e) => {
    setExLocalAuthority(e.target.value);
    setExLocalAuthorityError();
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    const confirm = window.confirm("Are you sure you want to cancel?");

    if (confirm) {
      clearNewClientSessionStorageItems();
      router.push({ pathname: linkFor("dashboard") });
    }
  };

  const setInvalidPostcode = () => {
    setPostcodeError(POSTCODE_NOT_FOUND);
    postcodeRef.current.setCustomValidity(POSTCODE_NOT_FOUND);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate postcode client-side before making API call
    if (!isValidPostcode(postcode)) {
      setInvalidPostcode();
      return;
    }

    setFormSending(true);

    const { isInvalidPostcode, outsideOfLendingCriteria, isValid } =
      await lendingCriteriaCheckModel(await getAccessToken(session), {
        exLocalAuthority,
        postcode,
      });

    const formIsValid = formRef.current.checkValidity();

    if (isValid && formIsValid) {
      router.push({
        pathname: linkFor("adviserDetails", {
          clientId,
          type: ADVISER_DETAILS_TYPE_NEW,
        }),
      });
      return;
    }

    setFormSending(false);

    // Checked client-side already, but just to be sure - in case the API says the postcode is invalid
    if (isInvalidPostcode) {
      setInvalidPostcode();
    }

    if (outsideOfLendingCriteria) {
      setPostcodeError(POSTCODE_OUTSIDE_OF_LENDING_CRITERIA);
      postcodeRef.current.setCustomValidity(
        POSTCODE_OUTSIDE_OF_LENDING_CRITERIA
      );
    }
  };

  return (
    <form name="lending-check-form" onSubmit={handleSubmit} ref={formRef}>
      <PageHeader
        backLink={{ href: linkFor("dashboard"), label: "Back to dashboard" }}
      />
      <Panel>
        <section className={cx("container")}>
          <header className={cx("header")}>
            <h1 className={cx("title")}>Lending check</h1>
            <p className={cx("description")}>
              We’ll use the below details to create an illustration. So please
              make sure the information is accurate.
            </p>
          </header>
          <hr />
          <div className={cx("formFields")}>
            <div className={cx("formField")}>
              <FormField.Text
                errorText={postcodeError}
                hasError={!!postcodeError}
                isRequired
                label="Postcode of the property to be mortgaged"
                onChange={handlePostcodeChange}
                ref={postcodeRef}
                tooltip={{
                  body: "This should be the postcode of the property which the mortgage will be secured on. If your customer is purchasing a new property, please use the new property's postcode here.",
                  title: "Postcode of property",
                }}
                value={postcode}
              />
            </div>
            {postcodeError && (
              <div className={cx("textContainer")} data-testid="postcode-error">
                {postcodeErrorMap[postcodeError]}
              </div>
            )}
            <div className={cx("formField")}>
              <FormField.RadioGroup
                group="ex-local-authority"
                hasError={exLocalAuthorityError}
                initialValue={exLocalAuthority}
                isRequired
                label="Is the property ex-local authority or housing association?"
                onChange={handleExLocalAuthorityChange}
                radios={[
                  { label: TRUTHY_STRING, value: TRUTHY_STRING },
                  { label: FALSY_STRING, value: FALSY_STRING },
                ]}
                tooltip={{
                  body: "We do accept some ex-local authority and ex-council houses, but do not accept any ex-local authority or ex-council flats or maisonettes. If this applies, please tick Yes to confirm it complies with our lending criteria.",
                  title: "Ex-local authority properties",
                }}
              />
            </div>
          </div>
        </section>
      </Panel>
      <FormActions onCancelClick={onCancelClick} isDisabled={formSending} />
    </form>
  );
};

LendingCheckForm.propTypes = {
  clientId: PropTypes.string,
  exLocalAuthority: PropTypes.oneOf([TRUTHY_STRING, FALSY_STRING]),
  postcode: PropTypes.string,
};

export default LendingCheckForm;
