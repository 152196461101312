import client from "services/client";

const propertyDetailsService = async (accessToken, clientId, requestData) => {
  client.accessToken = accessToken;

  return await client
    .post(`/clients/${clientId}/property`, requestData)
    .catch((error) => error);
};

export default propertyDetailsService;
