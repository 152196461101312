import client from "services/client";

const requestAccountUnlockEmail = async (email) => {
  return await client
    .post("/unlock_account", { email })
    .catch((error) => error);
};

const unlockAccount = async (token) => {
  return await client
    .patch("/unlock_account", { unlock_token: token })
    .catch((error) => error);
};

export { requestAccountUnlockEmail, unlockAccount };
