import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import classNames from "classnames/bind";
import { Button, FormField, NoticeBanner, Panel } from "components";
import { linkFor } from "utils";
import type { FormData } from "forms/AuthForm";
import styles from "./LoginForm.module.scss";
import appConfigsModel from "models/appConfigs";

const cx = classNames.bind(styles);

export interface LoginFormProps {
  formData: FormData;
  submitting: boolean;
  authError: boolean;
  onChange: (field: keyof FormData, value: string) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
}

const LoginForm = (props: LoginFormProps) => {
  const [showOtpMessage, setShowOtpMessage] = useState(false);
  const {
    query: { action },
  } = useRouter();

  const showOtpMessageConfig = async () => {
    const otpTempDisabled = await appConfigsModel(
      "otp_temp_disable_requirement"
    );
    setShowOtpMessage(otpTempDisabled === false);
  };

  useEffect(() => {
    showOtpMessageConfig();
  }, [showOtpMessage]);

  return (
    <Panel>
      <form className={cx("container")} onSubmit={props.onSubmit}>
        <header className={cx("header")}>
          <h2 className={cx("title")}>Login for professional advisers only</h2>
          {action === "sessionExpired" && (
            <NoticeBanner id="sessionExpired" hasCloseButton type="information">
              You have been automatically logged out, and will need to login
              again
            </NoticeBanner>
          )}
          {action === "logout" && (
            <NoticeBanner id="logout" hasCloseButton type="information">
              You have been successfully logged out of your Canada Life adviser
              account
            </NoticeBanner>
          )}
          {action === "unlock" && (
            <NoticeBanner id="unlock" hasCloseButton type="information">
              Your Canada Life adviser account has been successfully unlocked.
              Please log in to continue.
            </NoticeBanner>
          )}
        </header>
        <div className={cx("formFields")}>
          <FormField.Email
            errorText="Please check email address and try again"
            hasError={props.authError}
            isRequired
            label="Email address"
            onChange={(e) => props.onChange("email", e.target.value)}
            showRequiredText={false}
            value={props.formData.email}
            testId="login_page_email_input_field"
            errorTextTestId="login_page_email_error"
          />
          <FormField.Password
            errorText="Please check password and try again"
            hasError={props.authError}
            isRequired
            label="Password"
            onChange={(e) => props.onChange("password", e.target.value)}
            showRequiredText={false}
            value={props.formData.password}
            testId="login_page_password_input_field"
            errorTextTestId="login_page_password_error"
          />
        </div>
        {showOtpMessage && (
          <NoticeBanner id="otpEnabled" hasCloseButton type="information">
            One Time Passcodes(OTP) are now required. An OTP will be sent to
            your email address when you Log in.
          </NoticeBanner>
        )}
        <div className={cx("actions")}>
          <Button
            isDisabled={props.submitting}
            fullWidth
            testId="login_page_login_button"
          >
            Login
          </Button>
          <Link href={linkFor("forgotPassword")} className={cx("textLink")}>
            Forgot password?
          </Link>
        </div>
      </form>
    </Panel>
  );
};

export default LoginForm;
