import client from "services/client";

const createPassword = async (requestData) => {
  const request = await client
    .patch("/accept_invitation", requestData)
    .catch((error) => error);

  return request;
};

const requestForgotPasswordEmail = async (email) => {
  const request = await client
    .post("/passwords", email)
    .catch((error) => error);

  return request;
};

const resetPassword = async (requestData) => {
  const request = await client
    .patch("/passwords", requestData)
    .catch((error) => error);

  return request;
};

const updatePassword = async (accessToken, requestData) => {
  client.accessToken = accessToken;

  return await client
    .patch("/update_password", requestData)
    .catch((error) => error);
};

export default {
  createPassword,
  requestForgotPasswordEmail,
  resetPassword,
  updatePassword,
};
