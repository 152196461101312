import { API_ROUTES } from "lib/constants";
import client from "services/client";

export const lendingCriteriaCheck = async (accessToken, data) => {
  client.accessToken = accessToken;

  return await client
    .post(API_ROUTES.postcodes.lendingCriteriaCheck, data)
    .catch((error) => error);
};

export default { lendingCriteriaCheck };
