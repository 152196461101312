import client from "services/client";

export const update = async (accessToken, clientId, data) => {
  client.accessToken = accessToken;

  return await client
    .patch(`/clients/${clientId}/loan_information`, data)
    .catch((error) => error);
};

export const get = async (accessToken, clientId) => {
  client.accessToken = accessToken;

  return await client
    .get(`/clients/${clientId}/loan_information`)
    .catch((error) => error);
};

export default { update, get };
