import { useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames/bind";
import { signIn } from "next-auth/react";
import { Button, FormField, Panel } from "components";
import { linkFor } from "utils";
import styles from "./LoginBackdoorForm.module.scss";

const cx = classNames.bind(styles);

const LoginBackdoorForm = () => {
  const [password, setPassword] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [authError, setAuthError] = useState(false);
  const router = useRouter();

  const handlePasswordInputChange = (e) => {
    setPassword(e.target.value);
    setAuthError(false);
  };

  const {
    query: { code },
  } = useRouter();

  const handleLoginBackdoorFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const res = await signIn("kfi-oauth", {
      code,
      password,
      redirect: false,
    });

    if (res.status !== 200) {
      setSubmitting(false);
      setAuthError(true);
      return;
    }

    router.push(linkFor("dashboard"));
  };

  return (
    <Panel>
      <form
        className={cx("container")}
        onSubmit={handleLoginBackdoorFormSubmit}
      >
        <header className={cx("header")}>
          <h2 className={cx("title")}>Please enter your Admin password</h2>
        </header>
        <div className={cx("formFields")}>
          <FormField.Password
            errorText="Please check password and try again"
            hasError={authError}
            isRequired
            label="Password"
            onChange={handlePasswordInputChange}
            showRequiredText={false}
            value={password}
          />
        </div>
        <div className={cx("actions")}>
          <Button isDisabled={submitting} fullWidth>
            Login
          </Button>
        </div>
      </form>
    </Panel>
  );
};

export default LoginBackdoorForm;
