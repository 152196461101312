import { useRef, useState } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import passwordsService from "services/passwords";
import useErrorNoticeVisbility from "hooks/useErrorNoticeVisibility";
import { Button, ErrorNoticeBanner, FormField, Panel } from "components";
import { linkFor } from "utils";
import {
  ACCEPT_INVITATION,
  MIN_PASSWORD_LENGTH,
  RESET_PASSWORD,
  COMPLEX_PASSWORD_MESSAGE,
} from "lib/constants";
import styles from "./ResetPasswordForm.module.scss";

const cx = classNames.bind(styles);

const ResetPasswordForm = ({ token, type }) => {
  const formRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmationRef = useRef();

  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [loading, setLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [passwordCreated, setPasswordCreated] = useState(false);
  const bannerNotice = useRef();
  const [errors, setErrors] = useErrorNoticeVisbility(bannerNotice);

  const handlePasswordInputChange = (e) => {
    removeValidationErrors();
    setPassword(e.target.value);
  };

  const handlePasswordConfirmationInputChange = (e) => {
    removeValidationErrors();
    setPasswordConfirmation(e.target.value);
  };

  const handleResetPasswordFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    validateForm();
    const formIsValid = formRef.current.checkValidity();

    if (!formIsValid) {
      formRef.current.reportValidity();
      setLoading(false);
      return;
    }

    const response =
      type === RESET_PASSWORD
        ? await passwordsService.resetPassword({
            reset_password_token: token,
            password,
            password_confirmation: passwordConfirmation,
          })
        : await passwordsService.createPassword({
            invitation_token: token,
            password,
            password_confirmation: passwordConfirmation,
          });

    if (response.errors) {
      setErrors(response.errors);
      setLoading(false);
      return;
    }

    type === RESET_PASSWORD ? setPasswordReset(true) : setPasswordCreated(true);
  };

  const validateForm = () => {
    if (password.length < MIN_PASSWORD_LENGTH) {
      passwordRef.current.setCustomValidity("Password is too short");
    }
  };

  const removeValidationErrors = () => {
    passwordRef.current.setCustomValidity("");
    passwordConfirmationRef.current.setCustomValidity("");
  };

  if (passwordReset || passwordCreated) {
    return (
      <Panel>
        <div className={cx("container")}>
          <h2 className={cx("title")}>Success</h2>
          <p>
            You&apos;ve {passwordReset ? `reset` : `created`} your password.
            Please click the below button to return to the login page and sign
            in using your new password.
          </p>
          <div className={cx("actions")}>
            <Button fullWidth href={linkFor("login")}>
              Return to login
            </Button>
          </div>
        </div>
      </Panel>
    );
  }

  return (
    <Panel>
      <form
        name="reset-password-form"
        className={cx("container")}
        onSubmit={handleResetPasswordFormSubmit}
        ref={formRef}
      >
        <ErrorNoticeBanner
          errors={errors}
          ref={bannerNotice}
          defaultShowErrors={true}
        />
        <header className={cx("header")}>
          <h2 className={cx("title")}>Create a strong password</h2>
          <p className={cx("description")}>{COMPLEX_PASSWORD_MESSAGE}</p>
        </header>
        <div className={cx("formFields")}>
          <FormField.Password
            isRequired
            label="Create password"
            onChange={handlePasswordInputChange}
            value={password}
            helperText={`At least ${MIN_PASSWORD_LENGTH} characters`}
            ref={passwordRef}
          />
          <FormField.Password
            isRequired
            label="Confirm password"
            onChange={handlePasswordConfirmationInputChange}
            value={passwordConfirmation}
            helperText="Retype your new password"
            ref={passwordConfirmationRef}
          />
        </div>
        <div className={cx("actions")}>
          <Button isDisabled={loading} fullWidth>
            Save password
          </Button>
          <Link href={linkFor("login")} className={cx("textLink")}>
            Return to login
          </Link>
        </div>
      </form>
    </Panel>
  );
};

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  type: PropTypes.oneOf([RESET_PASSWORD, ACCEPT_INVITATION]),
};

export default ResetPasswordForm;
