import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import {
  ErrorNoticeBanner,
  FormActions,
  FormField,
  PageHeader,
  Panel,
} from "components";
import useSessionStorage from "hooks/useSessionStorage";
import { clearNewClientSessionStorageItems, linkFor } from "utils";
import styles from "./AdviserDetailsForm.module.scss";
import {
  ADVISER_DETAILS_TYPE_UPDATE,
  ADVISER_DETAILS_TYPE_NEW,
  CLIENT_DETAILS_TYPE_NEW,
  ADVISER_DETAILS_TYPE_NEW_CLIENT_EDIT,
  EXCLUSIVE_CLUB_LABEL,
} from "lib/constants";
import { useSession } from "next-auth/react";
import { getAccessToken } from "utils/oauth";
import useErrorNoticeVisibility from "hooks/useErrorNoticeVisibility";
import updateClubModel from "models/client/updateClub";

const cx = classNames.bind(styles);

const AdviserDetailsForm = ({ type, clubs, clubId }) => {
  const { data: session } = useSession();
  const [formSending, setFormSending] = useState();
  const [club, setClub] = useSessionStorage("clubId", clubId);
  const [exclusiveClub, setExclusiveClub] = useState();
  const noticeBanner = useRef();
  const [errors, setErrors] = useErrorNoticeVisibility(noticeBanner);
  const router = useRouter();
  const formRef = useRef();
  const clubRef = useRef();
  const { clientId } = router.query;

  let backLink;
  let cancelLink;
  let continueLink;
  let continueLabel;

  const assignClub = async () => {
    if (clubId) {
      setClub(clubId.toString());
      return;
    }
    if (clubs && clubs[0]?.label === EXCLUSIVE_CLUB_LABEL) {
      const exclusiveClub = clubs[0].options[0].value.toString();
      setExclusiveClub(exclusiveClub);
      setClub(exclusiveClub);
      return;
    }
    if (clubs && clubs[1]?.options[0]?.default === true) {
      const defaultPrivateClub = clubs[1].options[0].value.toString();
      setClub(defaultPrivateClub);
    }
  };

  useEffect(() => {
    assignClub();
  }, [clubId, clubs]);

  switch (type) {
    case ADVISER_DETAILS_TYPE_NEW:
    case ADVISER_DETAILS_TYPE_NEW_CLIENT_EDIT:
      backLink = linkFor("lendingCheck", { clientId });
      cancelLink = linkFor("dashboard");
      continueLink = linkFor("clientDetails", {
        clientId,
        type: CLIENT_DETAILS_TYPE_NEW,
      });
      continueLabel = "Continue";
      break;

    case ADVISER_DETAILS_TYPE_UPDATE:
      backLink = linkFor("client", { clientId });
      cancelLink = continueLink = linkFor("client", { clientId });
      continueLabel = "Save & Exit";
      break;
  }

  const handleClubChange = (e) => setClub(e.target.value);

  const onCancelClick = (e) => {
    e.preventDefault();
    const confirm = window.confirm("Are you sure you want to cancel?");

    if (confirm) {
      clearNewClientSessionStorageItems();
      router.push({ pathname: cancelLink });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formIsValid = formRef.current.checkValidity();

    if (
      type === ADVISER_DETAILS_TYPE_UPDATE ||
      type === ADVISER_DETAILS_TYPE_NEW_CLIENT_EDIT
    ) {
      let data = { clubId: club };
      const accessToken = await getAccessToken(session);
      const response = await updateClubModel({ accessToken, clientId, data });

      if (response.errors) {
        setFormSending(false);
        setErrors(response.errors);
        return;
      }
      sessionStorage.removeItem("clubId");
    }

    if (formIsValid) {
      setFormSending(true);
      router.push({ pathname: continueLink });
    }
  };

  return (
    <form name="lending-check-form" onSubmit={handleSubmit} ref={formRef}>
      <ErrorNoticeBanner errors={errors} ref={noticeBanner} />
      <PageHeader backLink={{ href: backLink, label: "Back" }} />
      <Panel>
        <section className={cx("container")}>
          <header className={cx("header")}>
            <h1 className={cx("title")}>Adviser details</h1>
            <p className={cx("description")}>
              Please provide the following information to us. We will use these
              details to create an illustration, so please ensure the
              information provided is accurate.
            </p>
          </header>
          <hr />
          <div className={cx("formFields")}>
            <div className={cx("formField")}>
              <FormField.Dropdown
                pleaseSelect={false}
                helperText="Optional"
                label="Choose club"
                onChange={handleClubChange}
                ref={clubRef}
                options={clubs}
                tooltip={{
                  body: "Please choose the club that you would like to submit this application through.",
                  title: "Choosing a Club",
                }}
                value={club}
                controlledValue={club}
                isDisabled={exclusiveClub !== undefined}
              />
            </div>
          </div>
        </section>
      </Panel>
      <FormActions
        onCancelClick={onCancelClick}
        isDisabled={formSending}
        continueLabel={continueLabel}
      />
    </form>
  );
};

AdviserDetailsForm.propTypes = {
  type: PropTypes.oneOf([
    ADVISER_DETAILS_TYPE_UPDATE,
    ADVISER_DETAILS_TYPE_NEW,
    ADVISER_DETAILS_TYPE_NEW_CLIENT_EDIT,
  ]).isRequired,
  clubs: PropTypes.array.isRequired,
  clubId: PropTypes.string,
};

export default AdviserDetailsForm;
