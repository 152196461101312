import { useEffect, useState, FormEvent } from "react";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";

import LoginForm from "forms/LoginForm";
import OTPForm from "forms/OTPForm";
import appConfigsModel from "models/appConfigs";

export interface FormData {
  email: string;
  password: string;
  otp?: string;
}

function AuthForm() {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [otpMaxAttempts, setOtpMaxAttempts] = useState(5);
  const router = useRouter();

  const fetchOtpMaxAttempts = async () => {
    const maxAttempts = await appConfigsModel("otp_user_max_attempts");
    setOtpMaxAttempts(maxAttempts);
  };

  useEffect(() => {
    fetchOtpMaxAttempts();
  }, [otpMaxAttempts]);

  useEffect(() => {
    if (resendOtp && !formData.otp) {
      performSignIn();
    }
  }, [resendOtp, formData.otp]);

  function onInputChange(field: string, value: string | undefined) {
    setFormData((state) => {
      const newState = { ...state };

      if (value === undefined) {
        delete newState[field];
      } else {
        newState[field] = value;
      }

      return newState;
    });
  }

  async function onSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();
    setResendOtp(false);
    performSignIn();
  }

  async function performSignIn() {
    setSubmitting(true);

    const res = await signIn("kfi-auth", {
      ...formData,
      redirect: false,
    });

    if (res?.url?.includes("otp=1")) {
      setShowOtp(true);
    }

    if (res.status !== 200) {
      let failedAttempts = Number(
        window.sessionStorage.getItem("otp-fail-attempts")
      );
      failedAttempts++;
      window.sessionStorage.setItem(
        "otp-fail-attempts",
        failedAttempts.toString()
      );
      setAuthError(true);
      if (failedAttempts >= otpMaxAttempts) {
        router.push("/login-help");
      }
    } else {
      window.sessionStorage.removeItem("otp-fail-attempts");
    }

    setSubmitting(false);
  }

  function onRequestOTP() {
    setAuthError(false);
    onInputChange("otp", undefined);
    setResendOtp(true);
  }

  if (showOtp) {
    return (
      <OTPForm
        email={formData.email}
        otp={formData.otp}
        submitting={submitting}
        authError={authError}
        resendOtp={resendOtp}
        onChange={onInputChange}
        onSubmit={onSubmit}
        onRequestOTP={onRequestOTP}
      />
    );
  }

  return (
    <LoginForm
      formData={formData}
      submitting={submitting}
      authError={authError}
      onChange={onInputChange}
      onSubmit={onSubmit}
    />
  );
}

export default AuthForm;
