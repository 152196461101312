import propertyDetailsService from "services/propertyDetails";
import clientsService from "services/clients";
import baseModel from "models/base";
import {
  presentPropertyForApiResolver,
  propertyDetailsPageResolver,
} from "resolvers";
import { PROPERTY_DETAILS_TYPE_UPDATE } from "lib/constants";

const props = async ({ accessToken, clientId } = {}) => {
  let data = { clientId, type: PROPERTY_DETAILS_TYPE_UPDATE };

  if (accessToken) {
    const clientResponse = await clientsService.get(accessToken, { clientId });

    data.client = clientResponse.data.client;
  }

  const resolvedData = propertyDetailsPageResolver(data);

  return {
    props: {
      ...resolvedData,
      base: baseModel(resolvedData.base),
    },
  };
};

const patch = async ({ accessToken, clientId, input }) => {
  const requestData = presentPropertyForApiResolver(input);

  const response = await propertyDetailsService(
    accessToken,
    clientId,
    requestData
  );

  return response;
};

export default { patch, props };
