import { useState } from "react";
import useSessionStorage from "hooks/useSessionStorage";
import { FORM_STORE_STATE, FORM_STORE_SESSION } from "lib/constants";

const useStateOrSessionStorage = ({ stateOrSession, initialValue, key }) => {
  const [stateItem, setStateItem] = useState(initialValue);
  const [sessionItem, setSessionItem] = useSessionStorage(key, initialValue);

  switch (stateOrSession) {
    case FORM_STORE_STATE: {
      return [stateItem, setStateItem];
    }
    case FORM_STORE_SESSION: {
      return [sessionItem, setSessionItem];
    }
    default: {
      console.warn(
        `stateOrSession value of "${stateOrSession}" is not recognised.`
      );
      return [stateItem, setStateItem];
    }
  }
};

export default useStateOrSessionStorage;
