import { MAXIMUM_AMOUNT_AVAILABLE, SPECIFIC_AMOUNT } from "lib/constants";
import { useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "age_next_birthday": {
      if (!action.value) {
        delete state.age_next_birthday;
        return { ...state };
      }

      return { ...state, age_next_birthday: { active: action.value }, page: 1 };
    }
    case "cashback": {
      if (!action.value) {
        delete state.cashback;
        return { ...state };
      }

      return {
        ...state,
        cashback: { active: action.value },
        page: 1,
      };
    }
    case "early_repayment_charge": {
      if (action.facet === "downsizing_protection") {
        if (!action.value) {
          delete state.early_repayment_charge.downsizing_protection;
          return { ...state };
        }

        return {
          ...state,
          early_repayment_charge: {
            ...state.early_repayment_charge,
            downsizing_protection: action.value,
          },
          page: 1,
        };
      }

      if (action.facet === "erc_waiver") {
        if (!action.value) {
          delete state.early_repayment_charge.erc_waiver;
          return { ...state, page: 1 };
        }

        return {
          ...state,
          early_repayment_charge: {
            ...state.early_repayment_charge,
            erc_waiver: action.value,
          },
          page: 1,
        };
      }
      break;
    }
    case "initial_advance": {
      const newState = {
        ...state,
        initial_advance: { type: action.facet },
        page: 1,
      };
      if (action.value) {
        newState.initial_advance.value = action.value;
      }

      return newState;
    }
    case "cash_reserve": {
      const newState = {
        ...state,
        cash_reserve: { type: action.facet },
        page: 1,
      };
      if (action.value) {
        newState.cash_reserve.value = action.value;
      }

      if (
        state.cashback &&
        (action.facet === MAXIMUM_AMOUNT_AVAILABLE ||
          action.facet === SPECIFIC_AMOUNT)
      ) {
        delete newState.cashback;
      }

      return newState;
    }
    case "inheritance_guarantee": {
      const parseBool = {
        true: true,
        false: false,
      };

      const newState = {
        ...state,
        inheritance_guarantee: { active: parseBool[action.facet] },
        page: 1,
      };

      if (action.value) {
        newState.inheritance_guarantee.percentage = action.value;
      }

      return newState;
    }
    case "sort": {
      const sortValues = action.value.split("_");
      const sort = sortValues[0];
      const order = sortValues[1];

      return { ...state, sort, order, page: 1 };
    }
    case "page": {
      return { ...state, page: action.value };
    }
    case "reset": {
      state = {};
      return { ...state };
    }
    default: {
      console.warn("reducer: default");
      return state;
    }
  }
};

const useProductListState = (initialState = { property_value: 100000 }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useProductListState;
