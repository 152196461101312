import classNames from "classnames/bind";

import { Button, FormField, NoticeBanner, Panel } from "components";
import type { FormData } from "forms/AuthForm";
import styles from "./OTPFrom.module.scss";
import Link from "next/link";

const cx = classNames.bind(styles);

export interface OTPFormProps {
  email: string;
  otp: string;
  submitting: boolean;
  authError: boolean;
  resendOtp: boolean;
  onChange: (field: keyof FormData, value: string) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onRequestOTP: () => void;
}

export function OTPForm(props: OTPFormProps) {
  return (
    <Panel>
      <div className={cx("container")}>
        <header className={cx("header")}>
          <h2 className={cx("title")}>One Time password (OTP)</h2>
          <p className={cx("paragraph")}>
            We have sent a One Time Password (OTP) to{" "}
            <strong>{props.email}</strong>.
          </p>
          <p className={cx("paragraph")}>
            It may take a few minutes to arrive. Please ensure you check your
            spam folder.
          </p>
        </header>
        <div>
          <FormField.Text
            label="One Time Password"
            value={props.otp}
            onChange={(e) => props.onChange("otp", e.target.value)}
            hasError={props.authError}
          />
          {props.authError && (
            <p className={cx("otpErrorMessage")}>Please check and try again</p>
          )}
        </div>
        {props.resendOtp && (
          <NoticeBanner id="resendOtp" hasCloseButton type="information">
            A new OTP has been sent
          </NoticeBanner>
        )}
        {props.authError && (
          <div className={cx("helpSection")}>
            <button
              type="button"
              className={cx("helpButton")}
              onClick={props.onRequestOTP}
            >
              Timed out? Request a new one.
            </button>
          </div>
        )}
        <div className={cx("actions")}>
          <Button
            type="button"
            isDisabled={props.submitting}
            fullWidth
            onClick={props.onSubmit}
          >
            Enter
          </Button>
        </div>
        <div className={cx("helpSection")}>
          <Link href="/login-help" className={cx("helpButton")}>
            Didn&apos;t receive a code?
          </Link>
        </div>
      </div>
    </Panel>
  );
}

export default OTPForm;
