import { useState } from "react";
import Link from "next/link";
import classNames from "classnames/bind";
import { Button, FormField, Panel } from "components";
import { requestAccountUnlockEmail } from "services/unlocks";
import { linkFor } from "utils";
import styles from "./UnlockAccountForm.module.scss";
import { EMAILS } from "lib/constants";

const cx = classNames.bind(styles);

const UnlockAccountForm = () => {
  const [email, setEmail] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleEmailInputChange = (e) => {
    setEmail(e.target.value);
    setErrors(false);
  };

  const handleUnlockAccountFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const response = await requestAccountUnlockEmail(email);

    if (response) {
      setSubmitting(false);
      setErrors(true);
      return;
    }

    setSuccess(true);
  };

  if (success) {
    return (
      <Panel>
        <div className={cx("container")}>
          <h2 className={cx("title")}>Account unlock link sent</h2>
          <p>
            If the email address you have provided is registered with Canada
            Life you will receive an account unlock email very soon.
          </p>
          <p>
            Please check your email inbox (and spam/junk) for your account
            unlock link. If you are having trouble logging in, please contact us
            by emailing{" "}
            <a
              className={cx("textLink")}
              href={`mailto:${EMAILS.adviserSupport}`}
            >
              {EMAILS.adviserSupport}
            </a>{" "}
            or calling{" "}
            <a className={cx("textLink")} href="tel:08000680212">
              0800 068 0212
            </a>
          </p>
          <Button fullWidth href={linkFor("login")}>
            Return to login
          </Button>
        </div>
      </Panel>
    );
  }

  return (
    <Panel>
      <form
        className={cx("container")}
        onSubmit={handleUnlockAccountFormSubmit}
      >
        <header className={cx("header")}>
          <h2 className={cx("title")}>Locked account</h2>
          <p>
            Please enter your email address and we will send you a link to
            unlock your account.
          </p>
        </header>
        <div className={cx("formFields")}>
          <FormField.Email
            errorText="Please check email address and try again"
            hasError={errors}
            isRequired
            label="Email address"
            onChange={handleEmailInputChange}
            showRequiredText={false}
            value={email}
          />
        </div>
        <div className={cx("actions")}>
          <Button isDisabled={submitting} fullWidth>
            Continue
          </Button>
          <Link href={linkFor("login")} className={cx("textLink")}>
            Return to login
          </Link>
        </div>
      </form>
    </Panel>
  );
};

export default UnlockAccountForm;
