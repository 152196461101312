import appConfigsService from "services/appConfigs";

const appConfigsModel = async (preferenceTag) => {
  const response = await appConfigsService.get(preferenceTag);

  if (response.errors) return response;

  return response.data.preference.value;
};

export default appConfigsModel;
